import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'

import { AuthContext } from '@services/AuthProvider'
import { CouponContext } from '@services/CouponProvider'
import { useTracking } from '@services/hooks/useTracking'
import {
  CardElement,
  IbanElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Button, Flex, Typography } from '@etvas/etvaskit'

import { I18nContext } from '@etvas/i18n'

import { ChargeText, ModalDialog, PriceOption } from '@shared/components'
import { PAYMENT_METHODS } from '@shared/constants'
import {
  calculateIncludedVAT,
  formatPrice,
  isTintedPreference,
  slugify
} from '@shared/funcs'
import { useBoolean } from '@shared/hooks/useBoolean'
import { useFeatureFlags } from '@shared/hooks/useFeatureFlags'
import { T } from '@shared/i18n'
import { purchaseStatus } from '@shared/models'
import { usePaymentMethods } from '@shared/services/usePaymentMethods'
import { usePurchaseProduct } from '@shared/services/usePurchaseProduct'

import PaymentMethodInput from './PaymentMethodInput'
import PollWaitActivation from './PollWaitActivation'
import PollWaitInvoice from './PollWaitInvoice'
import PurchaseDetails from './PurchaseDetails'
import PurchaseFailed from './PurchaseFailed'
import PurchasePaymentMethodView from './PurchasePaymentMethodView'
import PurchaseSuccess from './PurchaseSuccess'
import SepaMandate from './SepaMandate'
import modalAssets from './modalAssets'

const pollingModeVariants = {
  failed: 'failed',
  invoice: 'invoice',
  activation: 'activation',
  activated: 'activated'
}

const Purchase = ({ product, onCancel, onFinish, onUse, showModal }) => {
  const { allowedPaymentMethods } = useFeatureFlags()
  const stripe = useStripe()
  const elements = useElements()
  const { language } = useContext(I18nContext)
  const { currentUser } = useContext(AuthContext)
  const [purchaseId, setPurchaseId] = useState(null)
  const [isTokenizerBusy, setTokenizerBusy] = useState(false)
  const [isConfirmationRequired, setConfirmationRequired] = useState(false)
  const [isPaymentMethodChange, setPaymentMethodChange] = useState(false)

  const [pollingMode, setPollingMode] = useState(null)
  const [formStatus, setFormStatus] = useState(null)
  const [isModalShown, setIsModalShown] = useState(false)
  const [stripeInputError, setStripeInputError] = useState()
  const { track } = useTracking()

  if (elements) {
    elements.update({ locale: language })
  }

  const isReactivation = purchaseStatus.canBeReactivated(product.purchaseStatus)
  const assets = isReactivation
    ? modalAssets.activate
    : product.price
      ? modalAssets.purchase
      : modalAssets.get

  const { getPaymentCoupon, paymentCoupon } = useContext(CouponContext)

  const {
    requestAddPaymentMethod,
    requestAddSetupIntent,
    paymentMethods: allPaymentMethods,
    isAddingPaymentMethod,
    isPaymentMethodsFetching,
    errorGettingPaymentMethods
  } = usePaymentMethods()

  const { isPurchasing, requestPurchaseProduct } = usePurchaseProduct()

  const [selectedMethod, setSelectedMethod] = useState(PAYMENT_METHODS.card)
  const [isMandateDisplayRequired, openMandate, closeMandate] = useBoolean()
  const isCard = selectedMethod === PAYMENT_METHODS.card
  const isSepa = selectedMethod === PAYMENT_METHODS.sepa
  const PaymentMethodElement = isCard ? CardElement : IbanElement

  const purchaseSucceded = pollingMode === pollingModeVariants.activated
  const purchaseFailed = pollingMode === pollingModeVariants.failed

  const isMandateShown =
    isSepa && isMandateDisplayRequired && !purchaseSucceded && !purchaseFailed

  const paymentMethods = useMemo(
    () =>
      allPaymentMethods.filter(method =>
        allowedPaymentMethods.includes(method.type)
      ),
    [allPaymentMethods, allowedPaymentMethods]
  )

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setSelectedMethod(paymentMethods[0].type)
    }
  }, [paymentMethods])

  useEffect(() => {
    if (isPurchasing) {
      return
    }

    if (product?.purchaseStatus !== purchaseStatus.Purchased) {
      return
    }

    if (product?.purchaseId === purchaseId) {
      setPollingMode(pollingModeVariants.activated)
      return
    }

    if (!product.allowRepurchase) {
      console.error(`Product ${product.id} does not allow repurchase`)

      onCancel()
    }
  }, [isPurchasing, product, purchaseId, onCancel, track])

  useLayoutEffect(() => {
    if (!isModalShown)
      // prevents from executing this again when the PurchaseSuccess is rendered
      track({
        name: 'modal.show',
        value: 'purchase',
        context: {
          productId: product.id
        }
      })
    setIsModalShown(true)
  }, [track, product, isModalShown])

  useEffect(() => {
    if (errorGettingPaymentMethods) {
      setFormStatus('error.listPaymentMethods')
    }
  }, [errorGettingPaymentMethods])

  const hasTrial = useMemo(
    () => product.trialPeriod && product.trialUnit,
    [product]
  )

  const vatText = useMemo(() => {
    const vatTax = (
      calculateIncludedVAT(product.price, product.taxRate) / 100
    ).toFixed(2)

    const vatTaxWithCurrency = formatPrice(vatTax, product.currency)

    return {
      label: 'label.includingVat',
      args: [product.taxName, vatTaxWithCurrency, product.taxRate]
    }
  }, [product])

  const isFree = useMemo(() => product.price === 0, [product])

  const currentPaymentMethod = useMemo(() => {
    if (!paymentMethods || paymentMethods.length === 0) {
      return null
    }
    return paymentMethods[0]
  }, [paymentMethods])

  const isStripeBusy =
    !stripe ||
    !elements ||
    isPaymentMethodsFetching ||
    isAddingPaymentMethod ||
    isTokenizerBusy ||
    isPurchasing ||
    pollingMode !== null

  const showAddPaymentMethod = useMemo(
    () =>
      (!isPaymentMethodsFetching && paymentMethods.length === 0) ||
      isPaymentMethodChange ||
      pollingMode !== null,
    [
      isPaymentMethodChange,
      isPaymentMethodsFetching,
      paymentMethods.length,
      pollingMode
    ]
  )

  const _handleClose = () => {
    track({
      name: 'modal.hide',
      value: 'purchase',
      context: { productId: product.id }
    })
    return onCancel()
  }

  const _handleAddPaymentMethod = async () => {
    if (!stripe || !elements) {
      return
    }
    setTokenizerBusy(true)
    const paymentMethodType = isCard ? 'card' : 'sepa_debit'
    const result = await stripe.createPaymentMethod({
      type: paymentMethodType,
      [paymentMethodType]: elements.getElement(PaymentMethodElement),
      // eslint-disable-next-line camelcase
      billing_details: {
        email: currentUser.email,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        phone: currentUser.phoneNumber
      }
    })

    if (result.error) {
      console.error('* Error caught on stripe', result)
      setFormStatus('error.stripeCreatePaymentMethod')
      setTokenizerBusy(false)
      return false
    }

    setTokenizerBusy(false)

    const _resetStripeForm = () => {
      const element = elements.getElement(PaymentMethodElement)
      if (element) {
        element.clear()
      }
    }

    if (!hasTrial) {
      const { success } = await requestAddPaymentMethod(result.paymentMethod.id)
      if (!success) {
        setFormStatus(isCard ? 'text.invalidCard' : 'text.purchaseFailedSimple')
        _resetStripeForm()
        return false
      }
      setPaymentMethodChange(false)
      return true
    }
    const {
      success,
      paymentMethod: newPaymentMethod,
      setupIntent
    } = await requestAddSetupIntent(result.paymentMethod.id)
    if (!success) {
      setFormStatus('text.stripeError')
      _resetStripeForm()
      return false
    }
    if (setupIntent.status === 'succeeded') {
      setPaymentMethodChange(false)
      return true
    } else if (
      setupIntent.status !== 'requires_action' &&
      setupIntent.status !== 'requires_confirmation'
    ) {
      console.error('* Error processing setup intent status', setupIntent)
      setFormStatus('text.stripeError')
      _resetStripeForm()
      return false
    }
    if (isCard) {
      setFormStatus('text.cardRequiredAuth')
    }

    const confirmPaymentSetup = isCard
      ? stripe.confirmCardSetup
      : stripe.confirmSepaDebitSetup

    const confirmResponse = await confirmPaymentSetup(
      setupIntent.clientSecret,
      // eslint-disable-next-line camelcase
      { payment_method: newPaymentMethod.id }
    )
    if (confirmResponse.error) {
      _resetStripeForm()
      setFormStatus(
        isCard ? 'text.cardAuthFailed' : 'text.purchaseFailedSimple'
      )
      return false
    }
    setFormStatus(null)
    setPaymentMethodChange(false)
    return true
  }

  const _handlePaymentMethodChangeRequest = () => {
    if (!isStripeBusy) {
      setPaymentMethodChange(true)
    }
  }

  const _handlePurchase = async () => {
    if (!isFree && isSepa && !isMandateDisplayRequired) {
      return openMandate()
    }

    if (!isFree && showAddPaymentMethod) {
      const addPaymentMethod = await _handleAddPaymentMethod()
      if (!addPaymentMethod) {
        console.error(
          'Error while adding payment method: could not add payment method'
        )
        return
      }
    }

    if (
      product.purchaseStatus !== purchaseStatus.Purchasing ||
      product.purchaseStatus !== purchaseStatus.Resuming
    ) {
      const result = await requestPurchaseProduct(product.id)
      if (result.hasErrors) {
        setStripeInputError(result.errorMessage)
        return setPollingMode(null)
      }
      setPurchaseId(result.data.id)

      if (result?.data?.status === purchaseStatus.Purchased) {
        setPollingMode(pollingModeVariants.activated)
        return
      }
    }
    setPollingMode(pollingModeVariants.invoice)
  }

  const _handleInvoicePollFinished = async (invoice, paymentMethod) => {
    setPollingMode(null)
    if (
      invoice.paymentStatus === 'requires_action' ||
      invoice.paymentStatus === 'requires_confirmation' ||
      invoice.paymentStatus === 'requires_payment_method'
    ) {
      if (isCard) {
        setFormStatus('text.cardRequiredAuth')
      } else {
        setFormStatus('text.confirmingPayment')
      }
      const options = paymentMethod
        ? // eslint-disable-next-line camelcase
          { payment_method: paymentMethod.id }
        : undefined
      setConfirmationRequired(true)

      const confirmPayment = isCard
        ? stripe.confirmCardPayment
        : stripe.confirmSepaDebitPayment

      const response = await confirmPayment(invoice.clientSecret, options)

      setConfirmationRequired(false)
      if (response.error) {
        // We need new payment method
        track({
          name: 'purchase.failed',
          value: product.id,
          context: { productId: product.id }
        })
        setFormStatus('text.cardAuthFailed')
        setPaymentMethodChange(true)
        return
      }
      // here we passed the 3d secure with success
    }
    // here we don't need the 3d secure or we passed it already
    setPollingMode(pollingModeVariants.activation)
  }

  const _handleActivationPollFinished = activationStatus => {
    setPollingMode(null)

    if (activationStatus === purchaseStatus.Purchased) {
      // The product is successfully purchased
      track({
        name: 'purchase.succeeded',
        value: product.id,
        context: { productId: product.id }
      })
      setPollingMode(pollingModeVariants.activated)
      getPaymentCoupon()
    } else if (
      activationStatus === purchaseStatus.Failed ||
      activationStatus === purchaseStatus.Suspended
    ) {
      track({
        name: 'purchase.failed',
        value: product.id,
        context: { productId: product.id }
      })
      setFormStatus('text.purchaseFailedTryAgain')
      setPaymentMethodChange(true)
    } else {
      track({
        name: 'purchase.failed',
        value: product.id,
        context: { productId: product.id }
      })
      setFormStatus('text.purchaseFailedSimple')
      setPollingMode(pollingModeVariants.failed)
    }
  }

  const tinted = isTintedPreference()

  const handleStripeInputError = e => {
    if (e?.error?.message) {
      setStripeInputError(e.error.message)
      return
    }
    setStripeInputError()
  }

  const handlePaymentSelection = method => {
    _handlePaymentMethodChangeRequest()
    setSelectedMethod(method)
  }

  const showPrice = !isReactivation && stripe && !isStripeBusy && !isFree

  return (
    <PurchaseWrapper
      showModal={showModal}
      _handleClose={_handleClose}
      p={6}
      width={!showModal ? '100%' : ['90%', '400px']}>
      {isMandateShown && (
        <SepaMandate
          displayDetails
          confirm={() => {
            closeMandate()
            _handlePurchase()
          }}
        />
      )}

      <Box display={isMandateShown ? 'none' : 'block'}>
        {!purchaseSucceded && !purchaseFailed ? (
          <>
            <Typography variant='title32Bold'>
              <T label={assets.modalTitle} />
            </Typography>

            <Typography variant='base14Light' mt={4}>
              <T label={assets.aboutTo} />
            </Typography>
            <Box mt={[1, 6]}>
              <PurchaseDetails
                product={product}
                coupon={paymentCoupon}
                mb={2}
              />
              {!isPaymentMethodChange && currentPaymentMethod && !isFree ? (
                <PurchasePaymentMethodView
                  tinted={tinted}
                  paymentMethod={currentPaymentMethod}
                  isBusy={isStripeBusy}
                  onChange={_handlePaymentMethodChangeRequest}
                />
              ) : null}
              <ShowOnly
                condition={
                  showAddPaymentMethod && pollingMode === null && !isFree
                }>
                <PaymentMethodInput
                  tinted={tinted}
                  method={selectedMethod}
                  onChange={handleStripeInputError}
                  handlePaymentSelection={handlePaymentSelection}
                  disabled={
                    isTokenizerBusy ||
                    (!isPaymentMethodChange && !!currentPaymentMethod) ||
                    isAddingPaymentMethod
                  }
                />
              </ShowOnly>
              <Flex justifyContent='center'>
                {stripeInputError && pollingMode === null && (
                  <Typography
                    variant='base14Light'
                    color='statusError'
                    my={[0, 4]}>
                    {stripeInputError}
                  </Typography>
                )}
                {formStatus && pollingMode === null ? (
                  <Typography
                    variant='base14Light'
                    color='statusError'
                    my={[0, 4]}>
                    <T label={formStatus} />
                  </Typography>
                ) : null}
                {pollingMode === pollingModeVariants.invoice ? (
                  <PollWaitInvoice
                    my={[0, 4]}
                    product={product}
                    purchaseId={purchaseId}
                    paymentMethod={currentPaymentMethod}
                    onStopPolling={_handleInvoicePollFinished}
                  />
                ) : null}
                {pollingMode === pollingModeVariants.activation ? (
                  <PollWaitActivation
                    my={[0, 4]}
                    product={product}
                    purchaseId={purchaseId}
                    onStopPolling={_handleActivationPollFinished}
                  />
                ) : null}
              </Flex>
            </Box>
            {!isFree && (
              <ChargeText
                mt={7}
                variant='base14Light'
                fontWeight='400'
                color='textInputActive'
                product={product}
              />
            )}
            <Flex mt={6} justifyContent='center' flexDirection='column'>
              {showPrice && (
                <>
                  <Typography
                    variant='inputLabel'
                    textAlign='center'
                    fontSize='14px'>
                    <PriceOption product={product} />
                  </Typography>
                  <Typography
                    variant='inputLabel'
                    textAlign='center'
                    opacity={0.7}>
                    <T label={vatText.label} args={vatText.args} />
                  </Typography>
                </>
              )}

              <Button
                mt={3}
                mx={[2, 2, 8]}
                variant={isReactivation ? 'positive' : 'primary'}
                onClick={_handlePurchase}
                disabled={
                  !stripe ||
                  isStripeBusy ||
                  isConfirmationRequired ||
                  stripeInputError
                }
                data-testid='purchase-button'
                loading={!stripe || isStripeBusy}>
                {isReactivation ? (
                  <T label='label.reactivate' />
                ) : (
                  <T label={product.price ? 'label.purchase' : 'label.get'} />
                )}
              </Button>
            </Flex>
            <Typography variant='base14Light' mt={4} textAlign='center'>
              <T label={assets.clickAccept} />{' '}
              <RouterLink
                to={`/products/${slugify(product.name)}/${
                  product.id
                }/terms-and-conditions`}>
                <T label='label.TCPrivacyPolicy' />
              </RouterLink>
            </Typography>
          </>
        ) : null}
      </Box>
      {purchaseSucceded ? (
        <StatusCard noShadow={!showModal}>
          <PurchaseSuccess onUse={onUse} product={product} />
        </StatusCard>
      ) : null}
      {purchaseFailed ? (
        <StatusCard noShadow={!showModal}>
          <PurchaseFailed onFinish={onFinish} product={product} />
        </StatusCard>
      ) : null}
    </PurchaseWrapper>
  )
}

const StatusCard = styled(Box)`
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  ${props => (props.noShadow ? 'box-shadow: none;' : '')}
`

const ShowOnly = styled.div`
  display: ${({ condition }) => (condition ? 'block' : 'none')};
`

Purchase.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    imageURL: PropTypes.string,
    subscriptionType: PropTypes.string,
    subscriptionMonths: PropTypes.number,
    price: PropTypes.number,
    purchaseId: PropTypes.string,
    purchaseStatus: PropTypes.string,
    allowRepurchase: PropTypes.bool,
    trialPeriod: PropTypes.number,
    trialUnit: PropTypes.string,
    taxName: PropTypes.string,
    taxRate: PropTypes.number,
    currency: PropTypes.string
  }),
  onCancel: PropTypes.func,
  onUse: PropTypes.func,
  onFinish: PropTypes.func,
  showModal: PropTypes.bool
}

Purchase.defaultProps = {
  showModal: true
}

const PurchaseWrapper = ({
  showModal,
  title,
  _handleClose,
  children,
  ...props
}) =>
  showModal ? (
    <ModalDialog
      onDismiss={_handleClose}
      data-testid='purchase-modal'
      title={title}
      {...props}>
      {children}
    </ModalDialog>
  ) : (
    <Box {...props}>{children}</Box>
  )

PurchaseWrapper.propTypes = {
  children: PropTypes.node,
  showModal: PropTypes.bool,
  title: PropTypes.node,
  _handleClose: PropTypes.func
}

export default Purchase
