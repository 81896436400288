import { buildFormatPrice } from '@etvas/i18n'
import { formatPrice as i18nFormatPrice } from '@shared/i18n'
import { storage } from '@shared/funcs'

export const eurFromCents = (cents, trimmed) => {
  let price = `${(cents / 100).toFixed(2)}`
  if (!trimmed) {
    return price
  }
  while (price.endsWith('0')) {
    price = price.substr(0, -1)
  }
  return price.endsWith('.') ? price.substr(0, -1) : price
}

export const formatCurrency = (price, currency, trimmed) =>
  `${currency}${eurFromCents(price, trimmed)}`

export const formatPrice = (price, options) => {
  let priceFormatter = i18nFormatPrice
  if (storage.priceFormat) {
    priceFormatter = buildFormatPrice({ language: storage.priceFormat })
  }

  const currency = options?.currency ?? 'eur'
  const zero = options?.zero || priceFormatter(0, currency)
  return price !== 0 ? priceFormatter(price, currency) : zero
}

export const calculateIncludedVAT = (price, percent) =>
  (price / (1 + percent / 100) - price) * -1
